import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { UnitColumnFilters } from '../filter-columns';
import { ReadonlyCompleteMap } from '../../app.types';
import { NonHumanResourceListDto } from '../interfaces/dtos/non-human-resource-list.dto';

export type NonHumanResourcesListColumnKey = ObjectPropertyNames<NonHumanResourceListDto>;

// This defines the object that should implement all properties of 'NonHumanResourceListDto' exactly.
// It's used for linting mostly and discarded later.
const nonHumanResourcesListColumnsInfoObj: ListColumnsInfo<NonHumanResourceListDto> = {
  systemId: {
    label: 'System ID',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  name: {
    label: 'Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  remark: {
    label: 'Remark',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  type: {
    label: 'Type',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  version: {
    label: 'Version',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  serialNumber: {
    label: 'Serial Number',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  unitId: {
    label: 'Unit ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  unitName: {
    label: 'Unit',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  unitCoordinatorFullName: {
    label: 'Unit Coordinator',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  validFrom: {
    label: 'Valid From',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  validUntil: {
    label: 'Valid Until',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  countryCode: {
    label: 'Country Code',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  countryName: {
    label: 'Country',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  nonHumanResourceId: {
    label: 'Equipment, Infrastructure, Tools ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  workingHoursPerWeek: {
    label: 'Working Hours Per Week',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  payrollCompany: {
    label: 'Payroll Company',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  costCenter: {
    label: 'Cost Center',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  city: {
    label: 'City',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  divisionCode: {
    label: 'Division',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  businessUnitCode: {
    label: 'Business Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  productGroupCode: {
    label: 'Product Group',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  hourlyRateCategory: {
    label: 'common.activitytype',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const nonHumanResourcesListColumnsInfo: ReadonlyCompleteMap<
NonHumanResourcesListColumnKey,
ListColumnInfo
> = new Map([
  ['systemId', nonHumanResourcesListColumnsInfoObj.systemId],
  ['nonHumanResourceId', nonHumanResourcesListColumnsInfoObj.nonHumanResourceId],
  ['name', nonHumanResourcesListColumnsInfoObj.name],
  ['countryCode', nonHumanResourcesListColumnsInfoObj.countryCode],
  ['countryName', nonHumanResourcesListColumnsInfoObj.countryName],
  ['city', nonHumanResourcesListColumnsInfoObj.city],
  ['hourlyRateCategory', nonHumanResourcesListColumnsInfoObj.hourlyRateCategory],
  ['workingHoursPerWeek', nonHumanResourcesListColumnsInfoObj.workingHoursPerWeek],
  ['remark', nonHumanResourcesListColumnsInfoObj.remark],
  ['type', nonHumanResourcesListColumnsInfoObj.type],
  ['version', nonHumanResourcesListColumnsInfoObj.version],
  ['serialNumber', nonHumanResourcesListColumnsInfoObj.serialNumber],
  ['costCenter', nonHumanResourcesListColumnsInfoObj.costCenter],
  ['payrollCompany', nonHumanResourcesListColumnsInfoObj.payrollCompany],
  ['divisionCode', nonHumanResourcesListColumnsInfoObj.divisionCode],
  ['businessUnitCode', nonHumanResourcesListColumnsInfoObj.businessUnitCode],
  ['productGroupCode', nonHumanResourcesListColumnsInfoObj.productGroupCode],
  ['validFrom', nonHumanResourcesListColumnsInfoObj.validFrom],
  ['validUntil', nonHumanResourcesListColumnsInfoObj.validUntil],
  ['unitName', nonHumanResourcesListColumnsInfoObj.unitName],
  ['unitId', nonHumanResourcesListColumnsInfoObj.unitId],
  ['unitCoordinatorFullName', nonHumanResourcesListColumnsInfoObj.unitCoordinatorFullName],
]) as ReadonlyCompleteMap<NonHumanResourcesListColumnKey, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const nonHumanResourcesListColumnsWithFilters: (keyof UnitColumnFilters)[] = Array.from(
  nonHumanResourcesListColumnsInfo.entries(),
)
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof UnitColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
