import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastyService } from '../shared/toasty';
import { ProjectService } from '../api/services/project.service';
import { ErrorHandlerService } from './error-handler.service';
import { WorkPackageListOptions } from '../api/list-query-options';

@Injectable({
  providedIn: 'root',
})
export class ExportService implements OnDestroy {
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private injector: Injector,
    private errorHandlerService: ErrorHandlerService,
    private toastyService: ToastyService,
    private projectService: ProjectService,
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  provideWorkPackageExport(linkName: string = 'export file', options: WorkPackageListOptions): Subscription {
    this.toastyService.info('Your Export has started, this might take some time to complete.');
    return this.projectService
      .getProjectExportList$(options)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (fileDownload) => {
          this.toastyService.fileDownload('Your Export is ready', 'Click on the link to download', linkName, fileDownload);
        },
        (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Error wile preparing download link');
        }
      );
  }
}
