import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsightsService } from '../modules/application-insights';
import { ToastyService } from '../shared/toasty';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(
    private appInsightsService: ApplicationInsightsService,
    private injector: Injector,
  ) {
    super();
  }

  handleError(error: Error): void {
    super.handleError(error);

    this.appInsightsService.trackException({ exception: error });

    if (!environment.production) {
      const toastyService = this.injector.get(ToastyService);

      toastyService.error({
        title: `Unhandled exception${error.name !== 'Error' ? `: ${error.name}` : ''}`,
        msg: error.message,
      });
    }
  }
}
