import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { decorateModuleRef } from './module-decorators';

if (environment.production) {
  enableProdMode();
}

/**
 * This part unregisters the service worker
 * This is only needed until all are unregistred
 * I assume it can be removed in the next 1-2 releases
 */
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(decorateModuleRef)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
