import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import moment from 'moment';
import { CollappFilterDropdownListRadioSetting } from '../../models/collapp-filter-dropdown-list-radio.interface';
import { ProjectPlanningRangeStateModel } from '../../state/settings/project-planning-range/project-planning-range-state.model';
import { ProjectPlanningTotalsStateModel } from '../../state/settings/project-planning-totals/project-planning-totals-state.model';
import { ProjectPlanningFiltersSettingsModel } from '../../models/project-planning-filter-settings.interface';
import { CollAppApiService } from './collapp-api.service';
import { UserColumnFilters } from '../filter-columns';
import { UserDto } from '../interfaces/dtos/user.dto';
import { UserDtoModel } from '../models/dtos/user.dto.model';
import { UserCreateRequestModel } from '../models/requests/user-create.request.model';
import { UserUpdateRequestModel } from '../models/requests/user-update.request.model';
import { UserListQueryOptions, UserResourceListQueryOptions } from '../list-query-options';
import { UserCreateResponse } from '../interfaces/responses/user-create.response';
import { UserCreateResponseModel } from '../models/responses/user-create.response.model';
import { UserViewResponseModel } from '../models/responses/user-view.response.model';
import { UserViewResponse } from '../interfaces/responses/user-view.response';
import { UsersViewResponseModel } from '../models/responses/users-view.response.model';
import { UsersViewResponse } from '../interfaces/responses/users-view.response';
import { Unit, UnitIdentity } from '../../models/unit.interface';
import { UserSlimDto } from '../interfaces/dtos/user-slim.dto';
import { UserSlimDtoModel } from '../models/dtos/user-slim.dto.model';
import { ProjectListStateModel } from '../../state/settings/project-list';
import { UnitListStateModel } from '../../state/settings/unit-list';
import { UserListStateModel } from '../../state/settings/user-list';
import { WorkPackageListStateModel } from '../../state/settings/work-package-list';
import { WorkPackageQuestionListStateModel } from '../../state/settings/work-package-question-list';
import { UserSettingRequestModel } from '../models/requests/user-setting.request.model';
import { QuestionsListStateModel } from '../../state/settings/questions-list';
import { UserResourceListStateModel } from '../../state/settings/user-resource-list';
import { UserListViewResponse } from '../interfaces/responses/user-list-view.response';
import { UserListViewResponseModel } from '../models/responses/user-list-view.response.model';
import { AvailableResourcesResponse } from '../interfaces/responses/available-resources.response';
import { AvailableResourcesResponseModel } from '../models/responses/available-resources.response.model';
import { AttachmentListStateModel } from '../../state/settings/attachment-list/attachment-list-state.model';
import { NonHumanResourceListStateModel } from '../../state/settings/non-human-resource-list';
import { UnitPlanningUserListViewResponseModel } from '../models/responses/unit-planning-user-list-view.response.model';
import { UnitPlanningUserListViewResponse } from '../interfaces/responses/unit-planning-user-list-view.response';
import { NonHumanResourcePlanningListStateModel } from '../../state/settings/non-human-resource-planning-list';
import { UserNotificationViewResponseModel } from '../models/responses/user-notification-view.response.model';
import { UserNotificationViewResponse } from '../interfaces/responses/user-notification-view.response';
import { UserNotificationSettingsUpdateRequestModel } from '../models/requests/user-notification-settings-update.request.model';
import { UserNotificationSettingsDtoModel } from '../models/dtos/user-notification-settings.dto.model';
import { UserNotificationSettingsDto } from '../interfaces/dtos/user-notification-settings.dto';
import { SkillFilterDto } from '../interfaces/dtos/skill-filter.dto';
import { ActivitiesStateModel } from '../../state/settings/activities/activities-state.model';
import { DashboardWorkPackageViewType } from '../../models/dashboard-work-package-view-type.enum';
import { UserPlanningTotalsStateModel } from '../../state/settings/user-planning-totals';
import { UnitPlanningTotalsStateModel } from '../../state/settings/unit-planning-totals';
import { UnitPlanningRangeStateModel } from '../../state/settings/unit-planning-range';
import { UserPlanningRangeStateModel } from '../../state/settings/user-planning-range/user-planning-range-state.model';
import { TimecardRangeStateModel } from '../../state/settings/timecard-range';
import { TimesheetRangeStateModel } from '../../state/settings/timesheet-range';
import { WorkPackagePlanningRangeStateModel } from '../../state/settings/work-package-planning-range';
import { WorkPackagePlanningTotalsStateModel } from '../../state/settings/work-package-planning-totals';
import { TimesheetTotalsStateModel } from '../../state/settings/timesheet-totals';
import { WorkpackageSidepanelStateModel } from '../../state/settings/work-package-sidepanel/work-package-sidepanel-state.model';
import { UserPlanningSidepanelStateModel } from '../../state/settings/user-planning-sidepanel';
import { UnitPlanningSidepanelStateModel } from '../../state/settings/unit-planning-sidepanel';
import { UnitPlanningSidepanelIndexStateModel } from '../../state/settings/unit-planning-sidepanel-index';
import { UserPlanningSidepanelIndexStateModel } from '../../state/settings/user-planning-sidepanel-index';
import { WorkPackageSidepanelIndexStateModel } from '../../state/settings/work-package-sidepanel-index';
import { ProjectStructureFiltersSettingsModel } from '../../models/project-structure-filter-settings.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: CollAppApiService) { }

  /**
   * Gets the currently authenticated user
   */
  getCurrentUser$(): Observable<UserDtoModel> {
    return this.apiService
      .getCurrentUser$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserDto>) => UserDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets another user
   */
  getSubstituteUser$(userId: string): Observable<UserDtoModel | null> {
    return this.apiService
      .getSubstituteUser$(userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserDto>) => {
          const user = UserDtoModel.fromJSON(response.body!);
          if (user.userId === userId) {
            return user;
          }

          return null;
        }),
      );
  }

  /**
   * Gets the users view of the admin section
   *
   * @return {Observable<UsersViewResponseModel>}
   */
  getUsersView$(): Observable<UsersViewResponseModel> {
    return this.apiService
      .getUsersView$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UsersViewResponse>) => UsersViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a single user by id.
   *
   * @param {string} userId
   * @return {Observable<UserSlimDtoModel>}
   */
  getUserById$(userId: string): Observable<UserSlimDtoModel> {
    return this.apiService
      .getUserById$(userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserSlimDto>) => UserSlimDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a single user by email address
   *
   * @param {string} email
   * @return {Observable<UserDtoModel>}
   */
  getUserByEmail$(email: string): Observable<UserDtoModel> {
    return this.apiService
      .getUserByEmail$(email)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserDto>) => UserDtoModel.fromJSON(response.body!)),
      );
  }

  getAllUsersEmployeeNumber$(filterString: string, limit = 20): Observable<UserListViewResponseModel> {
    return this.apiService
      .getAllUsersEmployeeNumber$(filterString, limit)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserListViewResponse>) => UserListViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a view of an user by email address
   *
   * @param {string} email
   * @return {Observable<UserViewResponseModel>}
   *
   * @TODO Check if getting user by EMAIL instead of ID is really required
   */
  getUserViewByEmail$(email: string): Observable<UserViewResponseModel> {
    return this.apiService
      .getUserViewByEmail$(email)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserViewResponse>) => UserViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a single AAD user by email address
   *
   * @param {string} email
   * @return {Observable<UserDtoModel>}
   */
  getAdUserByEmail$(email: string): Observable<UserDtoModel> {
    return this.apiService
      .getAdUserByEmail$(email)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserDto>) => UserDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Returns a list of users whose names match the search string.
   * Max. 10 entries will be returned.
   *
   * @param search
   * @param localOnly  -  returns user from CollApp DB, false returns users from AD
   * @param validOnly  -  returns users that are currently valid, false returns all found users
   * @param [limitUnit]  -  reduces the list of users to this unit (including children)
   */
  findUsers$(
    search: string,
    localOnly: boolean,
    validOnly: boolean,
    limitUnit?: Unit | null,
    includeSupplierUsers: boolean = false,
    onlyCoordinators: boolean = false,
    isForWpCreator: boolean = false,
  ): Observable<UserListViewResponseModel> {
    const unitId = limitUnit ? limitUnit.unitId : undefined;
    const columnFilters: UserColumnFilters = {
      fullName: search,
    };

    // eslint-disable-next-line max-len
    const options = new UserListQueryOptions(0, 10, columnFilters, undefined, undefined, localOnly, validOnly, unitId, includeSupplierUsers, onlyCoordinators, isForWpCreator);

    return this.apiService
      .getUsers$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserListViewResponse>) => UserListViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Returns a list of users for the unit planning.
   */
  getUserPlanningResources$(
    unitId: number,
    search: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks: number,
    isForWpCreator?: boolean,
  ): Observable<UnitPlanningUserListViewResponseModel> {
    return this.apiService
      .getUserPlanningResources$(unitId, search, isoWeekYear, isoWeek, numberOfWeeks, isForWpCreator)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitPlanningUserListViewResponse>) => UnitPlanningUserListViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of all users
   *
   * @param {number} [pageIndex]
   * @param {number} [pageSize]
   * @param {UserColumnFilters} [columnFilters]
   * @param {string} [sortColumn]
   * @param {SortDirection} [sortDirection]
   * @param {boolean} [localOnly]
   * @return {Observable<UserListViewResponseModel>}
   */
  getUsers$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: UserColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
    localOnly?: boolean,
  ): Observable<UserListViewResponseModel> {
    const options = new UserListQueryOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection, localOnly);

    return this.apiService
      .getUsers$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserListViewResponse>) => UserListViewResponseModel.fromJSON(response.body!)),
      );
  }

  getAvailableResourceUsers$(
    unit?: UnitIdentity,
    projectId?: number,
    workPackageId?: number,
    excludeUserIds?: string[],
    excludeNhrIds?: string[],
    fullName?: string,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortDirection?: SortDirection,
    validFrom?: moment.Moment,
    validUntil?: moment.Moment,
    year?: number,
    week?: number,
    numberOfWeeks?: number,
    selectedSkills?: SkillFilterDto[],
  ): Observable<AvailableResourcesResponseModel> {
    const unitId = unit ? unit.unitId : undefined;
    const options = new UserResourceListQueryOptions(
      unitId,
      projectId,
      workPackageId,
      excludeUserIds,
      excludeNhrIds,
      fullName,
      pageIndex,
      pageSize,
      sortColumn,
      sortDirection,
      validFrom,
      validUntil,
      year,
      week,
      numberOfWeeks,
      selectedSkills,
    );

    return this.apiService
      .getAvailableResourceUsers$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<AvailableResourcesResponse>) => AvailableResourcesResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new user
   */
  postUser$(request: UserCreateRequestModel): Observable<UserCreateResponseModel> {
    return this.apiService
      .postUser$(request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserCreateResponse>) => UserCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing user
   */
  putUser$(request: UserUpdateRequestModel): Observable<void> {
    return this.apiService
      .putUser$(request.userId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  logoutUser$(): Observable<void> {
    return this.apiService
      .logoutUser$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Saves the given dashboard settings.
   *
   * @param settings
   */
  saveDashboardSettings$(settings: DashboardWorkPackageViewType[]): Observable<void> {
    return this.saveUserSetting$('DashboardWorkPackageFilters', settings.join(','));
  }

  /**
   * Saves the given project settings.
   *
   * @param settings
   */
  saveProjectListSettings$(settings: ProjectListStateModel): Observable<void> {
    return this.saveUserSetting$('ProjectsTable', JSON.stringify(settings));
  }

  /**
   * Saves the current state of the cross utilization
   */
  saveTimesheetCrossUtilizationSettings$(withCrossUtilization: boolean): Observable<void> {
    return this.saveUserSetting$('TimesheetWithCrossUtilizeUsers', withCrossUtilization);
  }

  /**
   * Saves the given unit settings.
   *
   * @param settings
   */
  saveUnitListSettings$(settings: UnitListStateModel): Observable<void> {
    return this.saveUserSetting$('UnitsTable', JSON.stringify(settings));
  }

  /**
   * Saves the given non-human resources settings.
   *
   * @param settings
   */
  saveNonHumanResourceListSettings$(settings: NonHumanResourceListStateModel): Observable<void> {
    return this.saveUserSetting$('NonHumanResourcesTable', JSON.stringify(settings));
  }

  /**
   * Saves the given work package activities settings.
   *
   * @param settings
   */
  saveActivitiesSettings$(settings: ActivitiesStateModel): Observable<void> {
    return this.saveUserSetting$('Activities', JSON.stringify(settings));
  }

  /**
   * Saves the given user settings.
   *
   * @param settings
   */
  saveUserListSettings$(settings: UserListStateModel): Observable<void> {
    return this.saveUserSetting$('UsersTable', JSON.stringify(settings));
  }

  /**
   * Saves the given work package settings.
   *
   * @param settings
   */
  saveWorkPackageListSettings$(settings: WorkPackageListStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackagesTable', JSON.stringify(settings));
  }

  /**
   * Saves the given work package question list settings.
   *
   * @param settings
   */
  SaveWorkPackageQuestionListSettings$(settings: WorkPackageQuestionListStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackageQuestionsTable', JSON.stringify(settings));
  }

  /**
   * Saves the given unit questions list settings.
   *
   * @param settings
   */
  SaveQuestionsListSettings$(settings: QuestionsListStateModel): Observable<void> {
    return this.saveUserSetting$('QuestionsTable', JSON.stringify(settings));
  }

  /**
   * Saves the given unit resource planning list settings.
   *
   * @param settings
   */
  SaveUserResourceListSettings$(settings: UserResourceListStateModel): Observable<void> {
    return this.saveUserSetting$('UserResourceTable', JSON.stringify(settings));
  }

  /**
   * Saves the given non-human resource WP planning list settings.
   *
   * @param settings
   */
  saveNonHumanResourcePlanningListSettings$(settings: NonHumanResourcePlanningListStateModel): Observable<void> {
    return this.saveUserSetting$('NonHumanResourcesPlanningTable', JSON.stringify(settings));
  }

  /**
   * Saves the given attachment list settings.
   *
   * @param settings
   */
  saveAttachmentSettings$(settings: AttachmentListStateModel): Observable<void> {
    return this.saveUserSetting$('AttachmentList', JSON.stringify(settings));
  }

  /**
   * Saves the given user planning totals settings.
   *
   * @param settings
   */
  saveUserPlanningTotalsSettings$(settings: UserPlanningTotalsStateModel): Observable<void> {
    return this.saveUserSetting$('UserPlanningTotals', JSON.stringify(settings.selectedTotals));
  }

  /**
   * Saves the given unit planning totals settings.
   *
   * @param settings
   */
  saveTimesheetTotalsSettings$(settings: TimesheetTotalsStateModel): Observable<void> {
    return this.saveUserSetting$('TimesheetTotals', JSON.stringify(settings.selectedTotals));
  }

  /**
   * Saves the given unit planning range settings.
   *
   * @param settings
   */
  saveProjectPlanningRangeSettings$(settings: ProjectPlanningRangeStateModel): Observable<void> {
    return this.saveUserSetting$('ProjectPlanningRange', JSON.stringify(settings.range?.toJSON()));
  }

  /**
   * Saves the given unitplanning totals & capacityunit settings.
   *
   * @param settings
   */
  saveUnitPlanningTotalsSettings$(settings: UnitPlanningTotalsStateModel): Observable<void> {
    return forkJoin([
      this.saveUserSetting$('UnitPlanningCapacityUnit', settings.selectedCapacityUnit?.toString()),
      this.saveUserSetting$('UnitPlanningTotals', JSON.stringify(settings.selectedTotals)),
    ]).pipe(
      mapTo(undefined),
    );
  }

  /**
   * Saves the given projectplanning totals & capacityunit settings.
   *
   * @param settings
   */
  saveProjectPlanningTotalsSettings$(settings: ProjectPlanningTotalsStateModel): Observable<void> {
    return forkJoin([
      this.saveUserSetting$('ProjectPlanningCapacityUnit', settings.selectedCapacityUnit?.toString() || ''),
      this.saveUserSetting$('ProjectPlanningTotals', JSON.stringify(settings.selectedTotals)),
    ]).pipe(
      mapTo(undefined),
    );
  }

  /**
   * Saves the given work package planning totals settings.
   *
   * @param settings
   */
  saveWorkPackagePlanningTotalsSettings$(settings: WorkPackagePlanningTotalsStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackagePlanningTotals', JSON.stringify(settings.selectedTotals));
  }

  /**
   * Saves the given work package sidepanel state.
   *
   * @param settings
   */
  saveWorkPackageSidepanel$(settings: WorkpackageSidepanelStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackageSidePanel', JSON.stringify(settings.isOpen));
  }

  /**
   * Saves the given userplanning sidepanel state.
   *
   * @param settings
   */
  saveUserPlanningSidePanel$(settings: UserPlanningSidepanelStateModel): Observable<void> {
    return this.saveUserSetting$('UserPlanningSidePanel', JSON.stringify(settings.isOpen));
  }

  /**
   * Saves the given unitPlanning sidepanel state.
   *
   * @param settings
   */
  saveUnitPlanningSidePanel$(settings: UnitPlanningSidepanelStateModel): Observable<void> {
    return this.saveUserSetting$('UnitPlanningSidePanel', JSON.stringify(settings.isOpen));
  }

  /**
   * Saves the given unitPlanning sidepanel index state.
   *
   * @param settings
   */
  saveUnitPlanningSidePanelIndex$(settings: UnitPlanningSidepanelIndexStateModel): Observable<void> {
    return this.saveUserSetting$('UnitPlanningSidePanelIndex', JSON.stringify(settings.index));
  }

  /**
   * Saves the given userPlanning sidepanel index state.
   *
   * @param settings
   */
  saveUserPlanningSidePanelIndex$(settings: UserPlanningSidepanelIndexStateModel): Observable<void> {
    return this.saveUserSetting$('UserPlanningSidePanelIndex', JSON.stringify(settings.index));
  }

  /**
   * Saves the given wp sidepanel index state.
   *
   * @param settings
   */
  saveWorkPackageSidePanelIndex$(settings: WorkPackageSidepanelIndexStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackageSidePanelIndex', JSON.stringify(settings.index));
  }

  /**
   * Saves the given unit planning range settings.
   *
   * @param settings
   */
  saveUnitPlanningRangeSettings$(settings: UnitPlanningRangeStateModel): Observable<void> {
    return this.saveUserSetting$('UnitPlanningRange', JSON.stringify(settings.range?.toJSON()));
  }

  /**
   * Saves the given user planning range settings.
   *
   * @param settings
   */
  saveUserPlanningRangeSettings$(settings: UserPlanningRangeStateModel): Observable<void> {
    return this.saveUserSetting$('UserPlanningRange', JSON.stringify(settings.range?.toJSON()));
  }

  /**
   * Saves the given timecard range settings.
   *
   * @param settings
   */
  saveTimecardRangeSettings$(settings: TimecardRangeStateModel): Observable<void> {
    return this.saveUserSetting$('TimecardRange', JSON.stringify(settings.range?.toJSON()));
  }

  /**
   * Saves the given timesheet range settings.
   *
   * @param settings
   */
  saveTimesheetRangeSettings$(settings: TimesheetRangeStateModel): Observable<void> {
    return this.saveUserSetting$('TimesheetRange', JSON.stringify(settings.range?.toJSON()));
  }

  /**
   * Send ProjectPlanningFilters in UserSettings
   * @param settings
   * @returns
   */
  saveProjectStructureFilters$(settings: ProjectStructureFiltersSettingsModel): Observable<void> {
    return this.saveUserSetting$('ProjectsStructureHeaderFilters', JSON.stringify(settings));
  }

  /**
   * Saves the given work package planning range settings.
   *
   * @param settings
   */
  saveWorkPackagePlanningRangeSettings$(settings: WorkPackagePlanningRangeStateModel): Observable<void> {
    return this.saveUserSetting$('WorkPackagePlanningRange', JSON.stringify(settings.range?.toJSON()));
  }

  getUserNotificationView$(): Observable<UserNotificationViewResponseModel> {
    return this.apiService
      .getUserNotificationView$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserNotificationViewResponse>) => UserNotificationViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  putUserNotificationView$(request: UserNotificationSettingsUpdateRequestModel): Observable<void> {
    return this.apiService
      .putUserNotificationView$(request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  getUserNotificationDefaults$(roleCode: string): Observable<UserNotificationSettingsDtoModel[]> {
    return this.apiService
      .getUserNotificationDefaults$(roleCode)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserNotificationSettingsDto[]>) => response.body!
          .map(UserNotificationSettingsDtoModel.fromJSON)),
      );
  }

  saveTimecardHeaderFilters$(payload: CollappFilterDropdownListRadioSetting[]): Observable<void> {
    return this.saveUserSetting$('TimecardHeaderfilters', JSON.stringify(payload));
  }

  /**
   * Send ProjectPlanningFilters in UserSettings
   * @param settings
   * @returns
   */
  saveProjectPlanningFilters$(settings: ProjectPlanningFiltersSettingsModel): Observable<void> {
    return this.saveUserSetting$('ProjectPlanningHeaderFilters', JSON.stringify(settings));
  }

  savePlanningHeaderFilters$(payload: CollappFilterDropdownListRadioSetting[]): Observable<void> {
    return this.saveUserSetting$('PlanningHeaderfilters', JSON.stringify(payload));
  }

  saveProjectPlanningCompactFilter$(payload: boolean): Observable<void> {
    return this.saveUserSetting$('ProjectPlanningCompactFilter', JSON.stringify(payload));
  }

  /**
   * Saves the given user setting provided as key-value pair.
   */
  private saveUserSetting$(key: string, value: string | boolean): Observable<void> {
    const request = new UserSettingRequestModel(
      key,
      value,
    );

    return this.apiService
      .putUserSettings$(request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }
}
